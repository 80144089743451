import * as React from "react";
import SectionHeader from "src/components/SectionHeader/SectionHeader";
import icon78 from "src/images/icon78.png";
import info1 from "src/images/infoparteners/img1.png";
import info2 from "src/images/infoparteners/img2.png";
import info3 from "src/images/infoparteners/img3.png";
import info4 from "src/images/infoparteners/img4.png";
import info5 from "src/images/infoparteners/img5.png";
import info6 from "src/images/infoparteners/img6.png";
import info7 from "src/images/infoparteners/img7.png";
import info8 from "src/images/infoparteners/img8.png";
import incon01 from "src/images/partners/01.png";
import incon02 from "src/images/partners/02.png";
import incon03 from "src/images/partners/03.png";
import incon04 from "src/images/partners/04.png";
import incon05 from "src/images/partners/05.png";

import styles from "./Partners.module.css";

interface Props {}

const Partners = () => {
  return (
    <div>
      <div>
        <SectionHeader header="Генеральный инфопартнер" />
        <div className={[styles.imgsRowWrapper, styles.mainPartner].join(" ")}>
          <ImgWrapper img={icon78} />
        </div>
      </div>
      <div>
        <SectionHeader header="Партнеры" />
        <div className={[styles.imgsRowWrapper, styles.partners].join(" ")}>
          <ImgWrapper img={incon01} />
          <ImgWrapper img={incon02} />
          <ImgWrapper img={incon03} />
          <ImgWrapper img={incon04} />
          <ImgWrapper img={incon05} />
        </div>
      </div>
      <div>
        <SectionHeader header="Инфопартнеры" />
        <div className={[styles.imgsRowWrapper, styles.infoPartners].join(" ")}>
          <ImgWrapper img={info8} />
          <ImgWrapper img={info7} />
          <ImgWrapper img={info2} />
          <ImgWrapper img={info4} />
          <ImgWrapper img={info3} />
          <ImgWrapper img={info5} />
          <ImgWrapper img={info6} />
          <ImgWrapper img={info1} />
        </div>
      </div>
    </div>
  );
};

const ImgWrapper = ({ img }) => {
  return (
    <div className={styles.imgWrapper}>
      <img src={img} />
    </div>
  );
};

export default Partners;
