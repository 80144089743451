import * as React from "react";
import Typography from "src/components/Typography";
import styles from "./About.module.css";

const About = () => {
  return (
    <section className={styles.aboutWrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.BG} />
        <div className={styles.contentWrapper}>
          <div className={styles.contentItem}>
            <Number number="3" />
            <Subtitle subtitle="дня" />
            <Text text="Насыщенная программа на разных площадках города" />
          </div>
          <div className={styles.contentItem}>
            <Number number=">30" />
            <Subtitle subtitle="спикеров" />
            <Text text="Продюсеров, режиссеров, кинокритиков, представителей кинокомпаний" />
          </div>
          <div className={styles.contentItem}>
            <Number number="5" />
            <Subtitle subtitle="питчингов" />
            <Text text="А также открытая площадка для презентации своих идей продюсерам и коллегам по киношному цеху" />
          </div>
        </div>
      </div>
    </section>
  );
};

const Number = ({ number }) => (
  <div>
    <Typography type="h1">{number}</Typography>
  </div>
);
const Subtitle = ({ subtitle }) => (
  <div className={styles.subheader}>
    <Typography type="h5">{subtitle}</Typography>
  </div>
);
const Text = ({ text }) => (
  <div className={styles.textWrapper}>
    <Typography type="text">{text}</Typography>
  </div>
);

export default About;
