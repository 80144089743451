import * as React from "react";
import Typography from "src/components/Typography";
import styles from "./Features.module.css";
import Item from "./Item";

class Features extends React.Component<any, any> {
  public render() {
    return (
      <div className={styles.featuresContainer}>
        <div className={styles.featuresHeader}>
          <Typography type="h4">Potential Project Market это:</Typography>
        </div>
        <div className={styles.featuresWrapper}>
          <Item
            number="01"
            header="ТРЕНДЫ"
            text="Главные тенденции киноиндустрии и рынка продакшена – в дискуссиях и на мастер-классах, через кейсы и истории от главных лиц российского кино."
          />
          <Item
            number="02"
            header="ИДЕИ"
            text="Ежегодные питчинги и сценарные конкурсы, где молодые авторы представляют свои идеи, продюсеры ищут новые проекты, а зрители вдохновляются кейсами и мотают на ус."
          />
          <Item
            number="03"
            header="НЕТВОРКИНГ"
            text="На Cinemarket каждый может попить кофе с продюсером, встретить «того самого» члена команды и проверить свои идеи на прочность, выступив перед коллегами."
          />
          <Item
            number="04"
            header="ЗНАНИЯ"
            text="Лекции, мастер-классы, встречи с создателями недавно вышедших фильмов, эксклюзивные кинопоказы и презентации."
          />
        </div>
      </div>
    );
  }
}

export default Features;
