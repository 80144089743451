import * as React from "react";
import Typography from "src/components/Typography";
import styles from "./Header.module.css";
import SubHeader from "./SubHeader";

const Header: React.SFC = () => {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerBG} />
      <div className={styles.headerTitleWrapper}>
        <Typography type="h4">
          Кто участвует в Potential Project Market
        </Typography>
      </div>
      <div className={styles.subHeadersWrapper}>
        <SubHeader text="Начинающие и практикующие кинематографисты" />
        <SubHeader text="Продюсеры, представители кинокомпаний и продакшенов" />
        <SubHeader text="Любителеи и ценители кино" />
        <SubHeader text="Создатели контента, блогеры и кинокритики" />
      </div>
    </div>
  );
};

export default Header;
