import * as React from "react";
import Typography from "src/components/Typography";
import styles from "./Header.module.css";

interface Props {
  text: string;
}

const SubHeader: React.SFC<Props> = (props) => {
  return (
    <div>
      <div className={styles.subheaderLine} />
      <Typography type="smallerText">{props.text}</Typography>
    </div>
  );
};

export default SubHeader;
