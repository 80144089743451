import * as React from "react";
import Typography from "src/components/Typography";
import styles from "./Item.module.css";

interface Props {
  number: string;
  header: string;
  text: string;
}

const Item: React.SFC<Props> = (props) => {
  return (
    <div className={styles.itemWrapper}>
      <div className={styles.itemDesktopHeader}>
        <Typography type="h1">{props.number}</Typography>
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.innerTextWrapper}>
          <div className={styles.itemMobileHeaderWrapper}>
            <Typography type="h1">{props.number}</Typography>
            <Typography type="h4">{props.header}</Typography>
          </div>
          <Typography type="text">{props.text}</Typography>
        </div>
      </div>
    </div>
  );
};

export default Item;
