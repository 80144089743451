import * as React from "react";
import AddVideos from "src/components/AddVideos/AddVideos";
import PageHeader from "src/components/PageHeader";
import TimePrice from "src/components/TimePrice";
import TrailerDescription from "src/components/TrailerDescription";
import Videos from "src/components/Videos";
import { Playlist as PlaylistType } from "src/types";
import About from "./About/About";
import Features from "./Features";
import Partners from "./Partners/Partners";
import styles from "./PPM.module.css";
import Teachers from "./Teachers";

interface Props {
  data: PlaylistType;
  handleVideoClick(): Promise<void>;
  handlePayButtonClick(): Promise<void>;
}

const Playlist: React.SFC<Props> = ({
  data,
  handleVideoClick,
  handlePayButtonClick,
}) => {
  return (
    <div>
      <div className={styles.topBackground} />
      <div className={styles.innerWrapper}>
        <PageHeader text={data.name} />
        <TimePrice
          duration={data.duration}
          good={data.good}
          showPrice={!!data.good && !!data.good.price && !data.payedByUser}
        />
        <TrailerDescription
          showPayButton={!!data.good && !!data.good.price && !data.payedByUser}
          handleClick={handlePayButtonClick}
          trailerUrl={data.trailer}
          description={data.description}
        />
        <Features />
        <About />
        <Videos
          videos={data.videos}
          handleVideoClick={handleVideoClick}
          playlistId={data._id}
        />
        <Teachers teachers={data.teachers} />
        <Partners />
        {data.additionalVideosIds?.length ? (
          <AddVideos playlistId={data._id} />
        ) : null}
      </div>
    </div>
  );
};

export default Playlist;
